import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import { Container } from "../../containers";
import { Link } from "../../links";
import { Select } from "../../selects";

export const FooterContainer = styled("div")`
  ${({ theme }): SerializedStyles => css`
    padding-top: ${theme.spacing(8)};
    background-color: ${theme.palette.site.footer.background} !important;
  `};
`;

export const LoadingSpinner = styled(Stack)`
  ${({ theme }): SerializedStyles => css`
    height: 45px;
    ${theme.breakpoints.down("md")} {
      height: 35px;
    }
  `}
`;

export const MainInfoBlock = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    text-align: center;
    ${theme.breakpoints.up("md")} {
      text-align: left;
    }
  `};
`;

export const LegalLink = styled(Link)`
  opacity: 0.5;
  text-transform: none;
`;

export const CopyrightText = styled(Typography)`
  opacity: 0.5;
  text-transform: none;
`;

export const DashedLine = styled("div")`
  ${({ theme }): SerializedStyles => css`
    background-image: linear-gradient(
      to right,
      #333 40%,
      ${theme.palette.primary.contrastText} 20%
    );
    background-position: top;
    background-size: 10px 1px;
    background-repeat: repeat-x;
    height: 1px;
    width: 100%;
    opacity: 0.5;
    margin: ${theme.spacing(4, 0)};
  `}
`;

export const BottomContainer = styled(Container)`
  ${({ theme }): SerializedStyles => css`
    margin-top: ${theme.spacing(4)};
    ${theme.breakpoints.up("md")} {
      padding-left: ${theme.spacing(11)};
      padding-right: ${theme.spacing(11)};
      max-width: calc(
        ${theme.cssMixins.containerMaxWidth} + ${theme.spacing(6)}
      ) !important;
    }
  `}
`;

export const BottomSection = styled(Stack)`
  ${({ theme }): SerializedStyles => css`
    background-color: ${theme.palette.site.footer.bottom};
    border-radius: ${theme.shape.siteBorderRadius.lg}
      ${theme.shape.siteBorderRadius.lg} 0 0;
    padding: ${theme.spacing(3, 2)};
    ${theme.breakpoints.up("md")} {
      padding: ${theme.spacing(3)};
    }
    > div {
      flex: 1 1 0px;
    }
  `}
`;

export const CopyrightSection = styled(Stack)`
  ${({ theme }): SerializedStyles => css`
    ${theme.breakpoints.down("md")} {
      order: 1;
    }
  `}
`;

export const Logo = styled("div")`
  ${({ theme }): SerializedStyles => css`
    height: 35px;
    width: 100%;
    ${theme.breakpoints.up("md")} {
      height: 45px;
    }
    > img {
      max-height: 100%;
      max-width: 100%;
    }
  `}
`;

export const MainContentBlock = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    ${theme.breakpoints.up("sm")} {
      margin-top: ${theme.spacing(8)};
      padding-left: ${theme.spacing(6)};
    }
    ${theme.breakpoints.up("lg")} {
      padding-left: ${theme.spacing(10)};
    }
  `}
`;

export const Icon = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    opacity: 1;
    > a:hover {
      opacity: 0.5;
      transition: ${theme.transitions.create("opacity", {
        duration: theme.transitions.duration.shorter,
      })};
    }
  `};
`;

export const Memberships = styled(Stack)`
  ${({ theme }): SerializedStyles => css`
    ${theme.breakpoints.down("md")} {
      margin-bottom: ${theme.spacing(4)} !important;
      order: 2;
    }
    ${theme.breakpoints.up("md")} {
      margin-left: 0 !important;
    }
    > span:before {
      color: ${theme.palette.site.footer.memberships} !important;
      font-size: 45px;
      ${theme.breakpoints.down("md")} {
        font-size: 35px;
      }
    }
  `};
`;

export const Sites = styled(Stack)`
  ${({ theme }): SerializedStyles => css`
    flex-wrap: wrap;
    ${theme.breakpoints.down("md")} {
      margin-bottom: ${theme.spacing(4)} !important;
      order: 3;
    }
  `};
`;

export const Separator = styled(Divider)`
  ${({ theme }): SerializedStyles => css`
    background-color: ${theme.palette.primary.contrastText};
    margin: 0 ${theme.spacing(2)};
  `}
`;

export const StyledSelect = styled(Select)`
  ${({ theme }): SerializedStyles => css`
    &.select-field {
      border: 10px solid red;
    }
    .MuiSelect-root.Mui-focused {
      .MuiSelect-select {
        background-color: red !important;
      }
    }
    .MuiSelect-root.Mui-focused .MuiSelect-select {
      background-color: red !important;
    }
    .MuiSelect-select {
      font-family: ${theme.typography.fontFamily};
      font-weight: 300;
      font-size: 0.8rem;
      background-color: ${theme.palette.site.footer
        .crossSiteDropdown} !important;
      &:focus {
        background-color: ${theme.palette.site.footer
          .crossSiteDropdown} !important;
      }
    }
  `}
`;

export const IconLink = styled(Link)`
  ${({ theme }): SerializedStyles => css`
    color: ${theme.palette.primary.contrastText};
    height: fit-content;
    width: fit-content;
    svg {
      height: 20px;
      width: 20px;
    }
  `}
`;
